import {
  GET_ALL_NEIGHBOURHOODS_START,
  GET_ALL_NEIGHBOURHOODS_SUCCESS,
  GET_ALL_NEIGHBOURHOODS_FAILED,
  GET_ALL_NEIGHBOURHOODS_RESET,
  CREATE_NEIGHBOURHOOD_START,
  CREATE_NEIGHBOURHOOD_SUCCESS,
  CREATE_NEIGHBOURHOOD_FAILED,
  CREATE_NEIGHBOURHOOD_RESET,
  DELETE_NEIGHBOURHOOD_START,
  DELETE_NEIGHBOURHOOD_SUCCESS,
  DELETE_NEIGHBOURHOOD_FAILED,
  DELETE_NEIGHBOURHOOD_RESET,
  UPDATE_NEIGHBOURHOOD_START,
  UPDATE_NEIGHBOURHOOD_SUCCESS,
  UPDATE_NEIGHBOURHOOD_FAILED,
  UPDATE_NEIGHBOURHOOD_RESET,
} from '../actions';

const initialLoadingState = {
  loading: false,
  success: false,
  data: null,
  error: false,
  errorMessage: null,
};
const initialState = {
  getAll: initialLoadingState,
  getOne: initialLoadingState,
  create: initialLoadingState,
  deleteOne: initialLoadingState,

  updateOne: initialLoadingState,
};

const neighbourhoodsReducer = (state = initialState, action) => {
  switch (action.type) {
    /**
     * =======================================================================
     * Get All Neighbourhoods
     * =======================================================================
     */
    case GET_ALL_NEIGHBOURHOODS_START:
      return { ...state, getAll: { ...action.payload } };
    case GET_ALL_NEIGHBOURHOODS_SUCCESS:
      return { ...state, getAll: { ...action.payload } };
    case GET_ALL_NEIGHBOURHOODS_FAILED:
      return { ...state, getAll: { ...action.payload } };
    case GET_ALL_NEIGHBOURHOODS_RESET:
      return { ...state, getAll: { ...initialLoadingState } };

    /**
     * =======================================================================
     * Create One Neighbourhood
     * =======================================================================
     */
    case CREATE_NEIGHBOURHOOD_START:
      return { ...state, create: { ...action.payload } };
    case CREATE_NEIGHBOURHOOD_SUCCESS:
      return { ...state, create: { ...action.payload } };
    case CREATE_NEIGHBOURHOOD_FAILED:
      return { ...state, create: { ...action.payload } };
    case CREATE_NEIGHBOURHOOD_RESET:
      return { ...state, create: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Update One Neighbourhood
     * =======================================================================
     */
    case UPDATE_NEIGHBOURHOOD_START:
      return { ...state, updateOne: { ...action.payload } };
    case UPDATE_NEIGHBOURHOOD_SUCCESS:
      return { ...state, updateOne: { ...action.payload } };
    case UPDATE_NEIGHBOURHOOD_FAILED:
      return { ...state, updateOne: { ...action.payload } };
    case UPDATE_NEIGHBOURHOOD_RESET:
      return { ...state, updateOne: { ...initialLoadingState } };

    /**
     * =======================================================================
     * Delete One Neighbourhood
     * =======================================================================
     */
    case DELETE_NEIGHBOURHOOD_START:
      return { ...state, deleteOne: { ...action.payload } };
    case DELETE_NEIGHBOURHOOD_SUCCESS:
      return { ...state, deleteOne: { ...action.payload } };
    case DELETE_NEIGHBOURHOOD_FAILED:
      return { ...state, deleteOne: { ...action.payload } };
    case DELETE_NEIGHBOURHOOD_RESET:
      return { ...state, deleteOne: { ...initialLoadingState } };
    default:
      return state;
  }
};

export default neighbourhoodsReducer;
