import {
  GET_ALL_COMPLAINTS_START,
  GET_ALL_COMPLAINTS_SUCCESS,
  GET_ALL_COMPLAINTS_FAILED,
  GET_ALL_COMPLAINTS_RESET,
  GET_ONE_COMPLAINT_START,
  GET_ONE_COMPLAINT_SUCCESS,
  GET_ONE_COMPLAINT_FAILED,
  GET_ONE_COMPLAINT_RESET,
  DELETE_COMPLAINT_START,
  DELETE_COMPLAINT_SUCCESS,
  DELETE_COMPLAINT_FAILED,
  DELETE_COMPLAINT_RESET,
  COUNT_COMPLAINTS_START,
  COUNT_COMPLAINTS_SUCCESS,
  COUNT_COMPLAINTS_FAILED,
  COUNT_COMPLAINTS_RESET,
  GET_NEIGHBOURHOOD_COMPLAINTS_START,
  GET_NEIGHBOURHOOD_COMPLAINTS_SUCCESS,
  GET_NEIGHBOURHOOD_COMPLAINTS_FAILED,
  GET_NEIGHBOURHOOD_COMPLAINTS_RESET,
} from '../actions';

const initialLoadingState = {
  loading: false,
  success: false,
  data: null,
  error: false,
  errorMessage: null,
};
const initialState = {
  getAll: initialLoadingState,
  getOne: initialLoadingState,
  deleteOne: initialLoadingState,
  countComplaints: initialLoadingState,
};

const complaintsReducer = (state = initialState, action) => {
  switch (action.type) {
    /**
     * =======================================================================
     * Get All Complaints
     * =======================================================================
     */
    case GET_ALL_COMPLAINTS_START:
      return { ...state, getAll: { ...action.payload } };
    case GET_ALL_COMPLAINTS_SUCCESS:
      return { ...state, getAll: { ...action.payload } };
    case GET_ALL_COMPLAINTS_FAILED:
      return { ...state, getAll: { ...action.payload } };
    case GET_ALL_COMPLAINTS_RESET:
      return { ...state, getAll: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Get One Complaint
     * =======================================================================
     */
    case GET_ONE_COMPLAINT_START:
      return { ...state, getOne: { ...action.payload } };
    case GET_ONE_COMPLAINT_SUCCESS:
      return { ...state, getOne: { ...action.payload } };
    case GET_ONE_COMPLAINT_FAILED:
      return { ...state, getOne: { ...action.payload } };
    case GET_ONE_COMPLAINT_RESET:
      return { ...state, getOne: { ...initialLoadingState } };

    /**
     * =======================================================================
     * Delete One Complaint
     * =======================================================================
     */
    case DELETE_COMPLAINT_START:
      return { ...state, deleteOne: { ...action.payload } };
    case DELETE_COMPLAINT_SUCCESS:
      return { ...state, deleteOne: { ...action.payload } };
    case DELETE_COMPLAINT_FAILED:
      return { ...state, deleteOne: { ...action.payload } };
    case DELETE_COMPLAINT_RESET:
      return { ...state, deleteOne: { ...initialLoadingState } };
    default:
      return state;

    /**
     * =======================================================================
     * Count Complaints
     * =======================================================================
     */
    case COUNT_COMPLAINTS_START:
      return { ...state, countComplaints: { ...action.payload } };
    case COUNT_COMPLAINTS_SUCCESS:
      return { ...state, countComplaints: { ...action.payload } };
    case COUNT_COMPLAINTS_FAILED:
      return { ...state, countComplaints: { ...action.payload } };
    case COUNT_COMPLAINTS_RESET:
      return { ...state, countComplaints: { ...initialLoadingState } };

    /**
     * =======================================================================
     * Get Neighbourhood Complaints
     * =======================================================================
     */
    case GET_NEIGHBOURHOOD_COMPLAINTS_START:
      return { ...state, getAll: { ...action.payload } };
    case GET_NEIGHBOURHOOD_COMPLAINTS_SUCCESS:
      return { ...state, getAll: { ...action.payload } };
    case GET_NEIGHBOURHOOD_COMPLAINTS_FAILED:
      return { ...state, getAll: { ...action.payload } };
    case GET_NEIGHBOURHOOD_COMPLAINTS_RESET:
      return { ...state, getAll: { ...initialLoadingState } };
  }
};

export default complaintsReducer;
