import {
  GET_ALL_EVENTS_START,
  GET_ALL_EVENTS_SUCCESS,
  GET_ALL_EVENTS_FAILED,
  GET_ALL_EVENTS_RESET,
  GET_ONE_EVENT_START,
  GET_ONE_EVENT_SUCCESS,
  GET_ONE_EVENT_FAILED,
  GET_ONE_EVENT_RESET,
  CREATE_EVENT_START,
  CREATE_EVENT_SUCCESS,
  CREATE_EVENT_FAILED,
  CREATE_EVENT_RESET,
  DELETE_EVENT_START,
  DELETE_EVENT_SUCCESS,
  DELETE_EVENT_FAILED,
  DELETE_EVENT_RESET,
  UPDATE_EVENT_START,
  UPDATE_EVENT_SUCCESS,
  UPDATE_EVENT_FAILED,
  UPDATE_EVENT_RESET,
  COUNT_EVENTS_START,
  COUNT_EVENTS_SUCCESS,
  COUNT_EVENTS_FAILED,
  COUNT_EVENTS_RESET,
} from '../actions';

const initialLoadingState = {
  loading: false,
  success: false,
  data: null,
  error: false,
  errorMessage: null,
};
const initialState = {
  getAll: initialLoadingState,
  getOne: initialLoadingState,
  create: initialLoadingState,
  deleteOne: initialLoadingState,
  updateOne: initialLoadingState,
  countEvents: initialLoadingState,
};

const eventsReducer = (state = initialState, action) => {
  switch (action.type) {
    /**
     * =======================================================================
     * Get All Events
     * =======================================================================
     */
    case GET_ALL_EVENTS_START:
      return { ...state, getAll: { ...action.payload } };
    case GET_ALL_EVENTS_SUCCESS:
      return { ...state, getAll: { ...action.payload } };
    case GET_ALL_EVENTS_FAILED:
      return { ...state, getAll: { ...action.payload } };
    case GET_ALL_EVENTS_RESET:
      return { ...state, getAll: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Get One Event
     * =======================================================================
     */
    case GET_ONE_EVENT_START:
      return { ...state, getOne: { ...action.payload } };
    case GET_ONE_EVENT_SUCCESS:
      return { ...state, getOne: { ...action.payload } };
    case GET_ONE_EVENT_FAILED:
      return { ...state, getOne: { ...action.payload } };
    case GET_ONE_EVENT_RESET:
      return { ...state, getOne: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Create One Event
     * =======================================================================
     */
    case CREATE_EVENT_START:
      return { ...state, create: { ...action.payload } };
    case CREATE_EVENT_SUCCESS:
      return { ...state, create: { ...action.payload } };
    case CREATE_EVENT_FAILED:
      return { ...state, create: { ...action.payload } };
    case CREATE_EVENT_RESET:
      return { ...state, create: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Update One Event
     * =======================================================================
     */
    case UPDATE_EVENT_START:
      return { ...state, updateOne: { ...action.payload } };
    case UPDATE_EVENT_SUCCESS:
      return { ...state, updateOne: { ...action.payload } };
    case UPDATE_EVENT_FAILED:
      return { ...state, updateOne: { ...action.payload } };
    case UPDATE_EVENT_RESET:
      return { ...state, updateOne: { ...initialLoadingState } };

    /**
     * =======================================================================
     * Delete One Event
     * =======================================================================
     */
    case DELETE_EVENT_START:
      return { ...state, deleteOne: { ...action.payload } };
    case DELETE_EVENT_SUCCESS:
      return { ...state, deleteOne: { ...action.payload } };
    case DELETE_EVENT_FAILED:
      return { ...state, deleteOne: { ...action.payload } };
    case DELETE_EVENT_RESET:
      return { ...state, deleteOne: { ...initialLoadingState } };
    default:
      return state;

    /**
     * =======================================================================
     * Count Events
     * =======================================================================
     */
    case COUNT_EVENTS_START:
      return { ...state, countEvents: { ...action.payload } };
    case COUNT_EVENTS_SUCCESS:
      return { ...state, countEvents: { ...action.payload } };
    case COUNT_EVENTS_FAILED:
      return { ...state, countEvents: { ...action.payload } };
    case COUNT_EVENTS_RESET:
      return { ...state, countEvents: { ...initialLoadingState } };
  }
};

export default eventsReducer;
