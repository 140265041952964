// Imports: local files.
import ApiClient from '../../services/ApiClient';

// Actions Types: Get All Users.
export const GET_ALL_USERS_START = 'GET_ALL_USERS_START';
export const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS';
export const GET_ALL_USERS_FAILED = 'GET_ALL_USERS_FAILED';
export const GET_ALL_USERS_RESET = 'GET_ALL_USERS_RESET';

// Actions Types: Get One User.
export const GET_ONE_USER_START = 'GET_ONE_USER_START';
export const GET_ONE_USER_SUCCESS = 'GET_ONE_USER_SUCCESS';
export const GET_ONE_USER_FAILED = 'GET_ONE_USER_FAILED';
export const GET_ONE_USER_RESET = 'GET_ONE_USER_RESET';

// Actions Types: Update User.
export const UPDATE_USER_START = 'UPDATE_USER_START';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILED = 'UPDATE_USER_FAILED';
export const UPDATE_USER_RESET = 'UPDATE_USER_RESET';

// Action Creators: Get All Users.
const getAllUsersStart = (payload) => ({ type: GET_ALL_USERS_START, payload });
const getAllUsersSuccess = (payload) => ({ type: GET_ALL_USERS_SUCCESS, payload });
const getAllUsersFailed = (payload) => ({ type: GET_ALL_USERS_FAILED, payload });
const getAllUsersReset = () => ({ type: GET_ALL_USERS_RESET });

// Action Creators: Get One User.
const getOneUserStart = (payload) => ({ type: GET_ONE_USER_START, payload });
const getOneUserSuccess = (payload) => ({ type: GET_ONE_USER_SUCCESS, payload });
const getOneUserFailed = (payload) => ({ type: GET_ONE_USER_FAILED, payload });
const getOneUserReset = () => ({ type: GET_ONE_USER_RESET });

// Action Creators: Update User.
const updateUserStart = (payload) => ({ type: UPDATE_USER_START, payload });
const updateUserSuccess = (payload) => ({ type: UPDATE_USER_SUCCESS, payload });
const updateUserFailed = (payload) => ({ type: UPDATE_USER_FAILED, payload });
const updateUserReset = () => ({ type: UPDATE_USER_RESET });

// Actions: Get All Users.
export const getAllUsers = (payload, options = {}) => {
    const { page, limit, pagination, select } = payload;
    const { } = options;
    return async (dispatch) => {
        try {
            dispatch(getAllUsersStart({ loading: true, success: false, data: null, error: false, errorMessage: null }));

            const result = await ApiClient.get('/users', { params: { page, limit, pagination, select } });
            if (result.data?.success) {
                const { users } = result.data.data;
                dispatch(
                    getAllUsersSuccess({ loading: false, success: true, data: { users }, error: false, errorMessage: null })
                );
            } else {
                const errorMessage = result.data?.error || 'Internal Server Error!';
                dispatch(
                    getAllUsersFailed({
                        loading: false,
                        success: false,
                        data: null,
                        error: true,
                        errorMessage: errorMessage,
                    })
                );

                dispatch({
                    type: '@snackbar/SNACKBAR_OPEN',
                    open: true,
                    message: errorMessage,
                    variant: 'alert',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                    alertSeverity: 'error',
                    close: true,
                });
            }
        } catch (error) {
            const errorMessage =
                error.response && error.response.data && error.response.data.error
                    ? error.response.data.error
                    : 'Something went wrong!';

            dispatch(
                getAllUsersFailed({
                    loading: false,
                    success: false,
                    data: null,
                    error: true,
                    errorMessage: errorMessage,
                })
            );

            dispatch({
                type: '@snackbar/SNACKBAR_OPEN',
                open: true,
                message: errorMessage,
                variant: 'alert',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
                alertSeverity: 'error',
                close: true,
            });
        }
    };
};

// Actions: Clear All Users.
export const clearAllUsers = () => getAllUsersReset();

// Actions: Get One User.
export const getOneUser = (payload, options = {}) => {
    const { userId } = payload;
    const { } = options;
    return async (dispatch) => {
        try {
            dispatch(getOneUserStart({ loading: true, success: false, data: null, error: false, errorMessage: null }));

            const result = await ApiClient.get(`/users/${userId}`);

            if (result.data?.success) {
                const { user } = result.data.data;
                dispatch(
                    getOneUserSuccess({ loading: false, success: true, data: { user }, error: false, errorMessage: null })
                );
            } else {
                const errorMessage = result.data?.error || 'Internal Server Error!';
                dispatch(
                    getOneUserFailed({
                        loading: false,
                        success: false,
                        data: null,
                        error: true,
                        errorMessage: errorMessage,
                    })
                );

                dispatch({
                    type: '@snackbar/SNACKBAR_OPEN',
                    open: true,
                    message: errorMessage,
                    variant: 'alert',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                    alertSeverity: 'error',
                    close: true,
                });
            }
        } catch (error) {
            const errorMessage =
                error.response && error.response.data && error.response.data.error
                    ? error.response.data.error
                    : 'Something went wrong!';

            dispatch(
                getOneUserFailed({
                    loading: false,
                    success: false,
                    data: null,
                    error: true,
                    errorMessage: errorMessage,
                })
            );

            dispatch({
                type: '@snackbar/SNACKBAR_OPEN',
                open: true,
                message: errorMessage,
                variant: 'alert',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
                alertSeverity: 'error',
                close: true,
            });
        }
    };
};

// Actions: Clear One User.
export const clearOneUser = () => getOneUserReset();

// Actions: Update New User.
export const updateUser = (payload, options = {}) => {
    const {
        name,
        surname,
        email,
        age,
        gender,
        aqi,
        haveDiseaseDiagnosis,
        energySource,
        hasChildren,
        hasChildrenDisease,
    } = payload;
    const { } = options;
    return async (dispatch) => {
        try {
            dispatch(updateUserStart({ loading: true, success: false, data: null, error: false, errorMessage: null }));

            const result = await ApiClient.post(`/users`, {
                name,
                surname,
                email,
                age,
                gender,
                aqi,
                haveDiseaseDiagnosis,
                energySource,
                hasChildren,
                hasChildrenDisease,
            });
            if (result.data?.success) {
                const { user } = result.data.data;
                dispatch(
                    updateUserSuccess({ loading: false, success: true, data: { user }, error: false, errorMessage: null })
                );

                dispatch({
                    type: '@snackbar/SNACKBAR_OPEN',
                    open: true,
                    message: 'User updated successfully!',
                    variant: 'alert',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                    alertSeverity: 'success',
                    close: true,
                });
            } else {
                const errorMessage = result.data?.error || 'Internal Server Error!';
                dispatch(
                    updateUserFailed({
                        loading: false,
                        success: false,
                        data: null,
                        error: true,
                        errorMessage: errorMessage,
                    })
                );

                dispatch({
                    type: '@snackbar/SNACKBAR_OPEN',
                    open: true,
                    message: errorMessage,
                    variant: 'alert',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                    alertSeverity: 'error',
                    close: true,
                });
            }
        } catch (error) {
            const errorMessage =
                error.response && error.response.data && error.response.data.error
                    ? error.response.data.error
                    : 'Something went wrong!';

            dispatch(
                updateUserFailed({
                    loading: false,
                    success: false,
                    data: null,
                    error: true,
                    errorMessage: errorMessage,
                })
            );

            dispatch({
                type: '@snackbar/SNACKBAR_OPEN',
                open: true,
                message: errorMessage,
                variant: 'alert',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
                alertSeverity: 'error',
                close: true,
            });
        }
    };
};

// Actions: Clear Created User.
export const clearUpadatedUser = () => updateUserReset();
