// Imports: local files.
import { dispatchError, dispatchLoading, dispatchSuccess, getAxiosError, showSnackbar } from 'utils/redux';
import ApiClient from '../../services/ApiClient';

// Actions Types: Get All Complaints.
export const GET_ALL_COMPLAINTS_START = 'GET_ALL_COMPLAINTS_START';
export const GET_ALL_COMPLAINTS_SUCCESS = 'GET_ALL_COMPLAINTS_SUCCESS';
export const GET_ALL_COMPLAINTS_FAILED = 'GET_ALL_COMPLAINTS_FAILED';
export const GET_ALL_COMPLAINTS_RESET = 'GET_ALL_COMPLAINTS_RESET';

// Actions Types: Get One Complaint.
export const GET_ONE_COMPLAINT_START = 'GET_ONE_COMPLAINT_START';
export const GET_ONE_COMPLAINT_SUCCESS = 'GET_ONE_COMPLAINT_SUCCESS';
export const GET_ONE_COMPLAINT_FAILED = 'GET_ONE_COMPLAINT_FAILED';
export const GET_ONE_COMPLAINT_RESET = 'GET_ONE_COMPLAINT_RESET';

// Actions Types: Delete One Complaint.
export const DELETE_COMPLAINT_START = 'DELETE_COMPLAINT_START';
export const DELETE_COMPLAINT_SUCCESS = 'DELETE_COMPLAINT_SUCCESS';
export const DELETE_COMPLAINT_FAILED = 'DELETE_COMPLAINT_FAILED ';
export const DELETE_COMPLAINT_RESET = 'DELETE_COMPLAINT_RESET';

// Actions Types: Count Events.
export const COUNT_COMPLAINTS_START = 'COUNT_COMPLAINTS_START';
export const COUNT_COMPLAINTS_SUCCESS = 'COUNT_COMPLAINTS_SUCCESS';
export const COUNT_COMPLAINTS_FAILED = 'COUNT_COMPLAINTS_FAILED ';
export const COUNT_COMPLAINTS_RESET = 'COUNT_COMPLAINTS_RESET';

// Actions Types: Get Neighbourhood Complaints.
export const GET_NEIGHBOURHOOD_COMPLAINTS_START = 'GET_NEIGHBOURHOOD_COMPLAINTS_START';
export const GET_NEIGHBOURHOOD_COMPLAINTS_SUCCESS = 'GET_NEIGHBOURHOOD_COMPLAINTS_SUCCESS';
export const GET_NEIGHBOURHOOD_COMPLAINTS_FAILED = 'GET_NEIGHBOURHOOD_COMPLAINTS_FAILED';
export const GET_NEIGHBOURHOOD_COMPLAINTS_RESET = 'GET_NEIGHBOURHOOD_COMPLAINTS_RESET';

// Action Creators: Get All Complaints.
const getAllComplaintsStart = (payload) => ({ type: GET_ALL_COMPLAINTS_START, payload });
const getAllComplaintsSuccess = (payload) => ({ type: GET_ALL_COMPLAINTS_SUCCESS, payload });
const getAllComplaintsFailed = (payload) => ({ type: GET_ALL_COMPLAINTS_FAILED, payload });
const getAllComplaintsReset = () => ({ type: GET_ALL_COMPLAINTS_RESET });

// Action Creators: Get One Complaint.
const getOneComplaintStart = (payload) => ({ type: GET_ONE_COMPLAINT_START, payload });
const getOneComplaintSuccess = (payload) => ({ type: GET_ONE_COMPLAINT_SUCCESS, payload });
const getOneComplaintFailed = (payload) => ({ type: GET_ONE_COMPLAINT_FAILED, payload });
const getOneComplaintReset = () => ({ type: GET_ONE_COMPLAINT_RESET });

// Action Creators: Delete One Complaint.
const deleteComplaintStart = (payload) => ({ type: DELETE_COMPLAINT_START, payload });
const deleteComplaintSuccess = (payload) => ({ type: DELETE_COMPLAINT_SUCCESS, payload });
const deleteComplaintFailed = (payload) => ({ type: DELETE_COMPLAINT_FAILED, payload });
const deleteComplaintReset = () => ({ type: DELETE_COMPLAINT_RESET });

// Action Creators: Count Complaints.
const countComplaintsStart = (payload) => ({ type: COUNT_COMPLAINTS_START, payload });
const countComplaintsSuccess = (payload) => ({ type: COUNT_COMPLAINTS_SUCCESS, payload });
const countComplaintsFailed = (payload) => ({ type: COUNT_COMPLAINTS_FAILED, payload });
const countComplaintsReset = () => ({ type: COUNT_COMPLAINTS_RESET });

// Action Creators: Get Neighbourhood Complaints.
const getNeighbourhoodComplaintsStart = (payload) => ({ type: GET_NEIGHBOURHOOD_COMPLAINTS_START, payload });
const getNeighbourhoodComplaintsSuccess = (payload) => ({ type: GET_NEIGHBOURHOOD_COMPLAINTS_SUCCESS, payload });
const getNeighbourhoodComplaintsFailed = (payload) => ({ type: GET_NEIGHBOURHOOD_COMPLAINTS_FAILED, payload });
const getNeighbourhoodComplaintsReset = () => ({ type: GET_NEIGHBOURHOOD_COMPLAINTS_RESET });

// Actions: Get All Complaints
export const getAllComplaints = (payload, options = {}) => {
  const { page, limit, pagination, neighbourhood } = payload;
  const {} = options;
  return async (dispatch) => {
    try {
      dispatch(getAllComplaintsStart({ loading: true, success: false, data: null, error: false, errorMessage: null }));

      const result = await ApiClient.get('/complaints', { params: { page, limit, pagination, neighbourhood } });

      if (result.data?.success) {
        const { complaints } = result.data.data;
        dispatch(
          getAllComplaintsSuccess({
            loading: false,
            success: true,
            data: { complaints },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        const errorMessage = result.data?.error || 'Internal Server Error!';
        dispatch(
          getAllComplaintsFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: errorMessage,
          })
        );

        dispatch({
          type: '@snackbar/SNACKBAR_OPEN',
          open: true,
          message: errorMessage,
          variant: 'alert',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          alertSeverity: 'error',
          close: true,
        });
      }
    } catch (error) {
      const errorMessage =
        error.response && error.response.data && error.response.data.error
          ? error.response.data.error
          : 'Something went wrong!';

      dispatch(
        getAllComplaintsFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: errorMessage,
        })
      );

      dispatch({
        type: '@snackbar/SNACKBAR_OPEN',
        open: true,
        message: errorMessage,
        variant: 'alert',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        alertSeverity: 'error',
        close: true,
      });
    }
  };
};

// Actions: Clear All Complaints.
export const clearAllComplaints = () => getAllComplaintsReset();

// Actions: Get One Complaint.
export const getOneComplaint = (payload, options = {}) => {
  const { complaintId } = payload;
  const {} = options;
  return async (dispatch) => {
    try {
      dispatch(getOneComplaintStart({ loading: true, success: false, data: null, error: false, errorMessage: null }));

      const result = await ApiClient.get(`/complaints/${complaintId}`);
      if (result.data?.success) {
        const { complaint } = result.data.data;
        dispatch(
          getOneComplaintSuccess({
            loading: false,
            success: true,
            data: { complaint },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        const errorMessage = result.data?.error || 'Internal Server Error!';
        dispatch(
          getOneComplaintFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: errorMessage,
          })
        );

        dispatch({
          type: '@snackbar/SNACKBAR_OPEN',
          open: true,
          message: errorMessage,
          variant: 'alert',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          alertSeverity: 'error',
          close: true,
        });
      }
    } catch (error) {
      const errorMessage =
        error.response && error.response.data && error.response.data.error
          ? error.response.data.error
          : 'Something went wrong!';

      dispatch(
        getOneComplaintFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: errorMessage,
        })
      );

      dispatch({
        type: '@snackbar/SNACKBAR_OPEN',
        open: true,
        message: errorMessage,
        variant: 'alert',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        alertSeverity: 'error',
        close: true,
      });
    }
  };
};

// Actions: Clear One Complaint.
export const clearOneComplaint = () => getOneComplaintReset();

// Actions: Delete One Complaint.
export const deleteOneComplaint = (payload, options = {}) => {
  const { complaintId } = payload;
  const {} = options;
  return async (dispatch) => {
    try {
      dispatch(deleteComplaintStart({ loading: true, success: false, data: null, error: false, errorMessage: null }));

      const result = await ApiClient.delete(`/complaints/${complaintId}`);
      if (result.data?.success) {
        const { complaint } = result.data.data;
        dispatch(
          deleteComplaintSuccess({
            loading: false,
            success: true,
            data: { complaint },
            error: false,
            errorMessage: null,
          })
        );

        dispatch({
          type: '@snackbar/SNACKBAR_OPEN',
          open: true,
          message: 'Complaint deleted successfully',
          variant: 'alert',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          alertSeverity: 'success',
          close: true,
        });
      } else {
        const errorMessage = result.data?.error || 'Internal Server Error!';
        dispatch(
          deleteComplaintFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: errorMessage,
          })
        );

        dispatch({
          type: '@snackbar/SNACKBAR_OPEN',
          open: true,
          message: errorMessage,
          variant: 'alert',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          alertSeverity: 'error',
          close: true,
        });
      }
    } catch (error) {
      const errorMessage =
        error.response && error.response.data && error.response.data.error
          ? error.response.data.error
          : 'Something went wrong!';

      dispatch(
        deleteComplaintFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: errorMessage,
        })
      );

      dispatch({
        type: '@snackbar/SNACKBAR_OPEN',
        open: true,
        message: errorMessage,
        variant: 'alert',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        alertSeverity: 'error',
        close: true,
      });
    }
  };
};

// Actions: Clear Deleted Complaint.
export const clearDeletedComplaint = () => deleteComplaintReset();

// Actions: Count Complaints.
export const countComplaints = (payload, options = {}) => {
  const { userId } = payload;

  const {} = options;
  return async (dispatch) => {
    try {
      dispatch(countComplaintsStart({ loading: true, success: false, data: null, error: false, errorMessage: null }));

      const result = await ApiClient.get(`/complaints/${userId}/count`);

      if (result.data?.success) {
        const complaint = result.data.data;
        dispatch(
          countComplaintsSuccess({
            loading: false,
            success: true,
            data: { complaint },
            error: false,
            errorMessage: null,
          })
        );

        // dispatch({
        //   type: '@snackbar/SNACKBAR_OPEN',
        //   open: true,
        //   message: 'Complaints counted successfully',
        //   variant: 'alert',
        //   anchorOrigin: {
        //     vertical: 'top',
        //     horizontal: 'right',
        //   },
        //   alertSeverity: 'success',
        //   close: true,
        // });
      } else {
        const errorMessage = result.data?.error || 'Internal Server Error!';
        dispatch(
          countComplaintsFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: errorMessage,
          })
        );

        dispatch({
          type: '@snackbar/SNACKBAR_OPEN',
          open: true,
          message: errorMessage,
          variant: 'alert',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          alertSeverity: 'error',
          close: true,
        });
      }
    } catch (error) {
      const errorMessage =
        error.response && error.response.data && error.response.data.error
          ? error.response.data.error
          : 'Something went wrong!';

      dispatch(
        countComplaintsFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: errorMessage,
        })
      );

      dispatch({
        type: '@snackbar/SNACKBAR_OPEN',
        open: true,
        message: errorMessage,
        variant: 'alert',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        alertSeverity: 'error',
        close: true,
      });
    }
  };
};

// Actions: Clear Count Complaints.
export const clearCountComplaints = () => countComplaintsReset();

// Actions: Get Neighbourhood Complaints
export const getNeighbourhoodComplaints = (payload, neighbourhood, options = {}) => {
  const { page, limit, pagination } = payload;

  const {} = options;
  return async (dispatch) => {
    try {
      dispatch(
        getNeighbourhoodComplaintsStart({ loading: true, success: false, data: null, error: false, errorMessage: null })
      );

      const result = await ApiClient.get(`/complaints/${neighbourhood}`, { params: { page, limit, pagination } });

      if (result.data?.success) {
        const { complaints } = result.data.data;
        dispatch(
          getNeighbourhoodComplaintsSuccess({
            loading: false,
            success: true,
            data: { complaints },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        const errorMessage = result.data?.error || 'Internal Server Error!';
        dispatch(
          getNeighbourhoodComplaintsFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: errorMessage,
          })
        );

        dispatch({
          type: '@snackbar/SNACKBAR_OPEN',
          open: true,
          message: errorMessage,
          variant: 'alert',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          alertSeverity: 'error',
          close: true,
        });
      }
    } catch (error) {
      const errorMessage =
        error.response && error.response.data && error.response.data.error
          ? error.response.data.error
          : 'Something went wrong!';

      dispatch(
        getNeighbourhoodComplaintsFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: errorMessage,
        })
      );

      dispatch({
        type: '@snackbar/SNACKBAR_OPEN',
        open: true,
        message: errorMessage,
        variant: 'alert',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        alertSeverity: 'error',
        close: true,
      });
    }
  };
};

// Actions: Clear Neighbourhood Complaints.
export const clearNeighbourhoodComplaints = () => getNeighbourhoodComplaintsReset();
