import { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { logout } from 'store/actions';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Chip } from '@mui/material';

// assets
import { IconLogout } from '@tabler/icons';

const ProfileSection = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const anchorRef = useRef(null);

  const handleLogout = async () => {
    await dispatch(logout());
  };

  return (
    <>
      <Chip
        sx={{
          height: '48px',
          alignItems: 'center',
          borderRadius: '27px',
          transition: 'all .2s ease-in-out',
          borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
          backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
          '&[aria-controls="menu-list-grow"], &:hover': {
            borderColor: theme.palette.primary.main,
            background: `${theme.palette.primary.main}!important`,
            color: theme.palette.primary.light,
            '& svg': {
              stroke: theme.palette.primary.light,
            },
          },
          '& .MuiChip-label': {
            lineHeight: 0,
          },
        }}
        label={<IconLogout stroke={1.5} size="1.5rem" color={theme.palette.primary.main} />}
        variant="outlined"
        ref={anchorRef}
        onClick={handleLogout}
        color="primary"
      />
    </>
  );
};

export default ProfileSection;
