import PropTypes from 'prop-types';
import jwtDecode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';

// project imports
import config from 'config';
import { useEffect } from 'react';

// ==============================|| GUEST GUARD ||============================== //

/**
 * Guest guard for routes having no auth required
 * @param {PropTypes.node} children children element/node
 */

const GuestGuard = ({ children }) => {
  const navigate = useNavigate();
  let isLoggedIn = false;

  const token = localStorage.getItem('lagjjaime-token');
  if (token) {
    const { exp } = jwtDecode(token);

    const expAt = new Date(0);
    expAt.setUTCMilliseconds(exp * 1000);

    if (new Date(Date.now()) < new Date(expAt)) isLoggedIn = true;
  }

  useEffect(() => {
    if (isLoggedIn) {
      navigate(config.defaultPath, { replace: true });
    }
  }, [isLoggedIn, navigate]);

  return children;
};

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default GuestGuard;
