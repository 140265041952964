// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconFolders, IconPaint, IconUser, IconTrees } from '@tabler/icons';
import { IconCalendarEvent } from '@tabler/icons';

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const other = {
  id: 'sample-docs-roadmap',
  type: 'group',
  children: [
    {
      id: 'neighbourhoods',
      title: <FormattedMessage id="Lagjet" />,
      type: 'item',
      url: '/dashboard/neighbourhoods',
      icon: IconTrees,
      breadcrumbs: false,
    },
    {
      id: 'users',
      title: <FormattedMessage id="Përdoruesit" />,
      type: 'item',
      url: '/dashboard/users',
      icon: IconUser,
      breadcrumbs: false,
    },
    {
      id: 'events',
      title: <FormattedMessage id="Eventet" />,
      type: 'item',
      url: '/dashboard/events',
      icon: IconCalendarEvent,
      breadcrumbs: false,
    },
    {
      id: 'jobs',
      title: <FormattedMessage id="Punët vullnetare" />,
      type: 'item',
      url: '/dashboard/jobs',
      icon: IconPaint,
      breadcrumbs: false,
    },
    {
      id: 'complaints',
      title: <FormattedMessage id="Ankesat" />,
      type: 'item',
      url: '/dashboard/complaints',
      icon: IconFolders,
      breadcrumbs: false,
    },
  ],
};

export default other;
