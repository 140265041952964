// Imports: local files.
import {
    GET_ALL_USERS_START,
    GET_ALL_USERS_SUCCESS,
    GET_ALL_USERS_FAILED,
    GET_ALL_USERS_RESET,
    GET_ONE_USER_START,
    GET_ONE_USER_SUCCESS,
    GET_ONE_USER_FAILED,
    GET_ONE_USER_RESET,
    UPDATE_USER_START,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_FAILED,
    UPDATE_USER_RESET,
} from '../actions';

const initialLoadingState = {
    loading: false,
    success: false,
    data: null,
    error: false,
    errorMessage: null,
};
const initialState = {
    getAll: initialLoadingState,
    getOne: initialLoadingState,
    updateOne: initialLoadingState,
};

const usersReducer = (state = initialState, action) => {
    switch (action.type) {
        /**
         * =======================================================================
         * Get All Users
         * =======================================================================
         */
        case GET_ALL_USERS_START:
            return { ...state, getAll: { ...action.payload } };
        case GET_ALL_USERS_SUCCESS:
            return { ...state, getAll: { ...action.payload } };
        case GET_ALL_USERS_FAILED:
            return { ...state, getAll: { ...action.payload } };
        case GET_ALL_USERS_RESET:
            return { ...state, getAll: { ...initialLoadingState } };
        /**
         * =======================================================================
         * Get One User
         * =======================================================================
         */
        case GET_ONE_USER_START:
            return { ...state, getOne: { ...action.payload } };
        case GET_ONE_USER_SUCCESS:
            return { ...state, getOne: { ...action.payload } };
        case GET_ONE_USER_FAILED:
            return { ...state, getOne: { ...action.payload } };
        case GET_ONE_USER_RESET:
            return { ...state, getOne: { ...initialLoadingState } };
        /**
         * =======================================================================
         * Update One User
         * =======================================================================
         */
        case UPDATE_USER_START:
            return { ...state, updateOne: { ...action.payload } };
        case UPDATE_USER_SUCCESS:
            return { ...state, updateOne: { ...action.payload } };
        case UPDATE_USER_FAILED:
            return { ...state, updateOne: { ...action.payload } };
        case UPDATE_USER_RESET:
            return { ...state, updateOne: { ...initialLoadingState } };

        default:
            return state;
    }
};

export default usersReducer;
