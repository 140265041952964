import {
  GET_ALL_JOBS_START,
  GET_ALL_JOBS_SUCCESS,
  GET_ALL_JOBS_FAILED,
  GET_ALL_JOBS_RESET,
  GET_ONE_JOB_START,
  GET_ONE_JOB_SUCCESS,
  GET_ONE_JOB_FAILED,
  GET_ONE_JOB_RESET,
  CREATE_JOB_START,
  CREATE_JOB_SUCCESS,
  CREATE_JOB_FAILED,
  CREATE_JOB_RESET,
  DELETE_JOB_START,
  DELETE_JOB_SUCCESS,
  DELETE_JOB_FAILED,
  DELETE_JOB_RESET,
  UPDATE_JOB_START,
  UPDATE_JOB_SUCCESS,
  UPDATE_JOB_FAILED,
  UPDATE_JOB_RESET,
  COUNT_JOBS_START,
  COUNT_JOBS_SUCCESS,
  COUNT_JOBS_FAILED,
  COUNT_JOBS_RESET,
  CONFIRM_JOB_START,
  CONFIRM_JOB_SUCCESS,
  CONFIRM_JOB_FAILED,
  CONFIRM_JOB_RESET,
} from '../actions';

const initialLoadingState = {
  loading: false,
  success: false,
  data: null,
  error: false,
  errorMessage: null,
};
const initialState = {
  getAll: initialLoadingState,
  getOne: initialLoadingState,
  create: initialLoadingState,
  deleteOne: initialLoadingState,
  updateOne: initialLoadingState,
  countJobs: initialLoadingState,
  confirmJob: initialLoadingState,
};

const jobsReducer = (state = initialState, action) => {
  switch (action.type) {
    /**
     * =======================================================================
     * Get All Jobs
     * =======================================================================
     */
    case GET_ALL_JOBS_START:
      return { ...state, getAll: { ...action.payload } };
    case GET_ALL_JOBS_SUCCESS:
      return { ...state, getAll: { ...action.payload } };
    case GET_ALL_JOBS_FAILED:
      return { ...state, getAll: { ...action.payload } };
    case GET_ALL_JOBS_RESET:
      return { ...state, getAll: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Get One Job
     * =======================================================================
     */
    case GET_ONE_JOB_START:
      return { ...state, getOne: { ...action.payload } };
    case GET_ONE_JOB_SUCCESS:
      return { ...state, getOne: { ...action.payload } };
    case GET_ONE_JOB_FAILED:
      return { ...state, getOne: { ...action.payload } };
    case GET_ONE_JOB_RESET:
      return { ...state, getOne: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Create One Job
     * =======================================================================
     */
    case CREATE_JOB_START:
      return { ...state, create: { ...action.payload } };
    case CREATE_JOB_SUCCESS:
      return { ...state, create: { ...action.payload } };
    case CREATE_JOB_FAILED:
      return { ...state, create: { ...action.payload } };
    case CREATE_JOB_RESET:
      return { ...state, create: { ...initialLoadingState } };
    /**
     * =======================================================================
     * Update One Job
     * =======================================================================
     */
    case UPDATE_JOB_START:
      return { ...state, updateOne: { ...action.payload } };
    case UPDATE_JOB_SUCCESS:
      return { ...state, updateOne: { ...action.payload } };
    case UPDATE_JOB_FAILED:
      return { ...state, updateOne: { ...action.payload } };
    case UPDATE_JOB_RESET:
      return { ...state, updateOne: { ...initialLoadingState } };

    /**
     * =======================================================================
     * Delete One Job
     * =======================================================================
     */
    case DELETE_JOB_START:
      return { ...state, deleteOne: { ...action.payload } };
    case DELETE_JOB_SUCCESS:
      return { ...state, deleteOne: { ...action.payload } };
    case DELETE_JOB_FAILED:
      return { ...state, deleteOne: { ...action.payload } };
    case DELETE_JOB_RESET:
      return { ...state, deleteOne: { ...initialLoadingState } };
    default:
      return state;

    /**
     * =======================================================================
     * Count Jobs
     * =======================================================================
     */
    case COUNT_JOBS_START:
      return { ...state, countJobs: { ...action.payload } };
    case COUNT_JOBS_SUCCESS:
      return { ...state, countJobs: { ...action.payload } };
    case COUNT_JOBS_FAILED:
      return { ...state, countJobs: { ...action.payload } };
    case COUNT_JOBS_RESET:
      return { ...state, countJobs: { ...initialLoadingState } };

    /**
     * =======================================================================
     * Confirm Job
     * =======================================================================
     */
    case CONFIRM_JOB_START:
      return { ...state, confirmJob: { ...action.payload } };
    case CONFIRM_JOB_SUCCESS:
      return { ...state, confirmJob: { ...action.payload } };
    case CONFIRM_JOB_FAILED:
      return { ...state, confirmJob: { ...action.payload } };
    case CONFIRM_JOB_RESET:
      return { ...state, confirmJob: { ...initialLoadingState } };
  }
};

export default jobsReducer;
