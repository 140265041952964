import { lazy } from 'react';

// project imports
import MinimalLayout from 'layout/MinimalLayout';
import Loadable from 'ui-component/Loadable';
const Privacy = Loadable(lazy(() => import('views/pages/privacy-policy')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [{ path: '/privacy-policy', element: <Privacy /> }],
};

export default AuthenticationRoutes;
