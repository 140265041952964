import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// reducer import
import customizationReducer from './customization';
import snackbarReducer from './snackbar';
import cartReducer from './cart';
import authReducer from './auth';
import usersReducer from './users';
import eventsReducer from './events';
import jobsReducer from './jobs';
import complaintsReducer from './complaints';
import neighbourhoodsReducer from './neighbourhoods';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  customization: customizationReducer,
  snackbar: snackbarReducer,
  auth: authReducer,
  users: usersReducer,
  events: eventsReducer,
  jobs: jobsReducer,
  complaints: complaintsReducer,
  neighbourhoods: neighbourhoodsReducer,
  cart: persistReducer(
    {
      key: 'cart',
      storage,
      keyPrefix: 'berry-',
    },
    cartReducer
  ),
});

export default reducer;
