// Imports: local files.
import { dispatchError, dispatchLoading, dispatchSuccess, getAxiosError, showSnackbar } from 'utils/redux';
import ApiClient from '../../services/ApiClient';

// Actions Types: Get All Neighbourhoods.
export const GET_ALL_NEIGHBOURHOODS_START = 'GET_ALL_NEIGHBOURHOODS_START';
export const GET_ALL_NEIGHBOURHOODS_SUCCESS = 'GET_ALL_NEIGHBOURHOODS_SUCCESS';
export const GET_ALL_NEIGHBOURHOODS_FAILED = 'GET_ALL_NEIGHBOURHOODS_FAILED';
export const GET_ALL_NEIGHBOURHOODS_RESET = 'GET_ALL_NEIGHBOURHOODS_RESET';

// Actions Types: Create Neighbourhood.
export const CREATE_NEIGHBOURHOOD_START = 'CREATE_NEIGHBOURHOOD_START';
export const CREATE_NEIGHBOURHOOD_SUCCESS = 'CREATE_NEIGHBOURHOOD_SUCCESS';
export const CREATE_NEIGHBOURHOOD_FAILED = 'CREATE_NEIGHBOURHOOD_FAILED';
export const CREATE_NEIGHBOURHOOD_RESET = 'CREATE_NEIGHBOURHOOD_RESET';

//Actions Type:Update One Neighbourhood.
export const UPDATE_NEIGHBOURHOOD_START = 'UPDATE_NEIGHBOURHOOD_START';
export const UPDATE_NEIGHBOURHOOD_SUCCESS = 'UPDATE_NEIGHBOURHOOD_SUCCESS';
export const UPDATE_NEIGHBOURHOOD_FAILED = 'UPDATE_NEIGHBOURHOOD_FAILED';
export const UPDATE_NEIGHBOURHOOD_RESET = 'UPDATE_NEIGHBOURHOOD_RESET';

// Actions Types: Delete One Neighbourhood.
export const DELETE_NEIGHBOURHOOD_START = 'DELETE_NEIGHBOURHOOD_START';
export const DELETE_NEIGHBOURHOOD_SUCCESS = 'DELETE_NEIGHBOURHOOD_SUCCESS';
export const DELETE_NEIGHBOURHOOD_FAILED = 'DELETE_NEIGHBOURHOOD_FAILED ';
export const DELETE_NEIGHBOURHOOD_RESET = 'DELETE_NEIGHBOURHOOD_RESET';

// Action Creators: Get All Neighbourhoods.
const getAllNeighbourhoodsStart = (payload) => ({ type: GET_ALL_NEIGHBOURHOODS_START, payload });
const getAllNeighbourhoodsSuccess = (payload) => ({ type: GET_ALL_NEIGHBOURHOODS_SUCCESS, payload });
const getAllNeighbourhoodsFailed = (payload) => ({ type: GET_ALL_NEIGHBOURHOODS_FAILED, payload });
const getAllNeighbourhoodsReset = () => ({ type: GET_ALL_NEIGHBOURHOODS_RESET });

// Action Creators: Create Neighbourhood.
const createNeighbourhoodStart = (payload) => ({ type: CREATE_NEIGHBOURHOOD_START, payload });
const createNeighbourhoodSuccess = (payload) => ({ type: CREATE_NEIGHBOURHOOD_SUCCESS, payload });
const createNeighbourhoodFailed = (payload) => ({ type: CREATE_NEIGHBOURHOOD_FAILED, payload });
const createNeighbourhoodReset = () => ({ type: CREATE_NEIGHBOURHOOD_RESET });

//Actions Creators: Update one Neighbourhood.
const updateNeighbourhoodStart = (payload) => ({ type: UPDATE_NEIGHBOURHOOD_START, payload });
const updateNeighbourhoodSuccess = (payload) => ({ type: UPDATE_NEIGHBOURHOOD_SUCCESS, payload });
const updateNeighbourhoodFailed = (payload) => ({ type: UPDATE_NEIGHBOURHOOD_FAILED, payload });
const updateNeighbourhoodReset = () => ({ type: UPDATE_NEIGHBOURHOOD_RESET });

// Action Creators: Delete One Neighbourhood.
const deleteNeighbourhoodStart = (payload) => ({ type: DELETE_NEIGHBOURHOOD_START, payload });
const deleteNeighbourhoodSuccess = (payload) => ({ type: DELETE_NEIGHBOURHOOD_SUCCESS, payload });
const deleteNeighbourhoodFailed = (payload) => ({ type: DELETE_NEIGHBOURHOOD_FAILED, payload });
const deleteNeighbourhoodReset = () => ({ type: DELETE_NEIGHBOURHOOD_RESET });

// Actions: Get All Neighbourhoods
export const getAllNeighbourhoods = (payload, options = {}) => {
  const { page, limit, pagination, neighbourhood } = payload;
  const {} = options;
  return async (dispatch) => {
    try {
      dispatch(
        getAllNeighbourhoodsStart({ loading: true, success: false, data: null, error: false, errorMessage: null })
      );

      const result = await ApiClient.get('/neighbourhoods', { params: { page, limit, pagination, neighbourhood } });

      if (result.data?.success) {
        const { neighbourhoods } = result.data.data;
        dispatch(
          getAllNeighbourhoodsSuccess({
            loading: false,
            success: true,
            data: { neighbourhoods },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        const errorMessage = result.data?.error || 'Internal Server Error!';
        dispatch(
          getAllNeighbourhoodsFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: errorMessage,
          })
        );

        dispatch({
          type: '@snackbar/SNACKBAR_OPEN',
          open: true,
          message: errorMessage,
          variant: 'alert',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          alertSeverity: 'error',
          close: true,
        });
      }
    } catch (error) {
      const errorMessage =
        error.response && error.response.data && error.response.data.error
          ? error.response.data.error
          : 'Something went wrong!';

      dispatch(
        getAllNeighbourhoodsFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: errorMessage,
        })
      );

      dispatch({
        type: '@snackbar/SNACKBAR_OPEN',
        open: true,
        message: errorMessage,
        variant: 'alert',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        alertSeverity: 'error',
        close: true,
      });
    }
  };
};

// Actions: Clear All Neighbourhoods.
export const clearAllNeighbourhoods = () => getAllNeighbourhoodsReset();

// Actions: Create New Neighbourhood.
export const createNeighbourhood = (payload, options = {}) => {
  const {} = options;
  return async (dispatch) => {
    try {
      dispatchLoading(dispatch, createNeighbourhoodStart);
      const fields = ['name'];
      const formData = new FormData();
      fields.forEach((field) => payload[field] && formData.append(field, payload[field]));

      const result = await ApiClient.post(`/neighbourhoods`, formData);
      if (result.data?.success) {
        const { neighbourhood } = result.data.data;

        dispatchSuccess(dispatch, createNeighbourhoodSuccess, neighbourhood);
        showSnackbar(dispatch, 'Neighbourhood created successfully', 'success');
      } else {
        const errorMessage = result.data?.error || 'Something went wrong!';
        dispatchError(dispatch, createNeighbourhoodFailed, errorMessage);
        showSnackbar(dispatch, errorMessage, 'error');
      }
    } catch (error) {
      const errorMessage = getAxiosError(error);

      dispatchError(dispatch, createNeighbourhoodFailed, errorMessage);
      showSnackbar(dispatch, errorMessage, 'error');
    }
  };
};

// Actions: Clear Created Neighbourhood.
export const clearCreatedNeighbourhood = () => createNeighbourhoodReset();

//Actions: Update One Neighbourhood
export const updateNeighbourhood = (payload, options = {}) => {
  const { neighbourhoodId } = payload;
  const {} = options;
  return async (dispatch) => {
    try {
      dispatchLoading(dispatch, updateNeighbourhoodStart);
      const fields = ['name'];
      const formData = new FormData();
      fields.forEach((field) => payload[field] && formData.append(field, payload[field]));
      const result = await ApiClient.put(`/neighbourhoods/${neighbourhoodId}`, formData);
      if (result.data?.success) {
        const { neighbourhood } = result.data.data;
        dispatchSuccess(dispatch, updateNeighbourhoodSuccess, neighbourhood);
        showSnackbar(dispatch, 'Neighbourhood updated successfully', 'success');
      } else {
        const errorMessage = result.data?.error || 'Something went wrong!';
        dispatchError(dispatch, updateNeighbourhoodFailed, errorMessage);
        showSnackbar(dispatch, errorMessage, 'error');
      }
    } catch (error) {
      const errorMessage = getAxiosError(error);

      dispatchError(dispatch, updateNeighbourhoodFailed, errorMessage);
      showSnackbar(dispatch, errorMessage, 'error');
    }
  };
};

// Actions: Clear Update One Neighbourhood.
export const clearUpdateNeighbourhood = () => updateNeighbourhoodReset();

// Actions: Delete One Neighbourhood.
export const deleteOneNeighbourhood = (payload, options = {}) => {
  const { neighbourhoodId } = payload;
  const {} = options;
  return async (dispatch) => {
    try {
      dispatch(
        deleteNeighbourhoodStart({ loading: true, success: false, data: null, error: false, errorMessage: null })
      );

      const result = await ApiClient.delete(`/neighbourhoods/${neighbourhoodId}`);
      if (result.data?.success) {
        const { neighbourhood } = result.data.data;
        dispatch(
          deleteNeighbourhoodSuccess({
            loading: false,
            success: true,
            data: { neighbourhood },
            error: false,
            errorMessage: null,
          })
        );

        dispatch({
          type: '@snackbar/SNACKBAR_OPEN',
          open: true,
          message: 'Neighbourhood deleted successfully',
          variant: 'alert',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          alertSeverity: 'success',
          close: true,
        });
      } else {
        const errorMessage = result.data?.error || 'Internal Server Error!';
        dispatch(
          deleteNeighbourhoodFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: errorMessage,
          })
        );

        dispatch({
          type: '@snackbar/SNACKBAR_OPEN',
          open: true,
          message: errorMessage,
          variant: 'alert',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          alertSeverity: 'error',
          close: true,
        });
      }
    } catch (error) {
      const errorMessage =
        error.response && error.response.data && error.response.data.error
          ? error.response.data.error
          : 'Something went wrong!';

      dispatch(
        deleteNeighbourhoodFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: errorMessage,
        })
      );

      dispatch({
        type: '@snackbar/SNACKBAR_OPEN',
        open: true,
        message: errorMessage,
        variant: 'alert',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        alertSeverity: 'error',
        close: true,
      });
    }
  };
};

// Actions: Clear Deleted Neighbourhood.
export const clearDeletedNeighbourhood = () => deleteNeighbourhoodReset();
