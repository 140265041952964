// Imports: local files.
import { dispatchError, dispatchLoading, dispatchSuccess, getAxiosError, showSnackbar } from 'utils/redux';
import ApiClient from '../../services/ApiClient';

// Actions Types: Get All Jobs.
export const GET_ALL_JOBS_START = 'GET_ALL_JOBS_START';
export const GET_ALL_JOBS_SUCCESS = 'GET_ALL_JOBS_SUCCESS';
export const GET_ALL_JOBS_FAILED = 'GET_ALL_JOBS_FAILED';
export const GET_ALL_JOBS_RESET = 'GET_ALL_JOBS_RESET';

// Actions Types: Get One Job.
export const GET_ONE_JOB_START = 'GET_ONE_JOB_START';
export const GET_ONE_JOB_SUCCESS = 'GET_ONE_JOB_SUCCESS';
export const GET_ONE_JOB_FAILED = 'GET_ONE_JOB_FAILED';
export const GET_ONE_JOB_RESET = 'GET_ONE_JOB_RESET';

// Actions Types: Create Job.
export const CREATE_JOB_START = 'CREATE_JOB_START';
export const CREATE_JOB_SUCCESS = 'CREATE_JOB_SUCCESS';
export const CREATE_JOB_FAILED = 'CREATE_JOB_FAILED';
export const CREATE_JOB_RESET = 'CREATE_JOB_RESET';

//Actions Type:Update One Job.
export const UPDATE_JOB_START = 'UPDATE_JOB_START';
export const UPDATE_JOB_SUCCESS = 'UPDATE_JOB_SUCCESS';
export const UPDATE_JOB_FAILED = 'UPDATE_JOB_FAILED';
export const UPDATE_JOB_RESET = 'UPDATE_JOB_RESET';

// Actions Types: Delete One Job.
export const DELETE_JOB_START = 'DELETE_JOB_START';
export const DELETE_JOB_SUCCESS = 'DELETE_JOB_SUCCESS';
export const DELETE_JOB_FAILED = 'DELETE_JOB_FAILED ';
export const DELETE_JOB_RESET = 'DELETE_JOB_RESET';

// Actions Types: Count Jobs.
export const COUNT_JOBS_START = 'COUNT_JOBS_START';
export const COUNT_JOBS_SUCCESS = 'COUNT_JOBS_SUCCESS';
export const COUNT_JOBS_FAILED = 'COUNT_JOBS_FAILED ';
export const COUNT_JOBS_RESET = 'COUNT_JOBS_RESET';

// Actions Types: Confirm Job.
export const CONFIRM_JOB_START = 'CONFIRM_JOB_START';
export const CONFIRM_JOB_SUCCESS = 'CONFIRM_JOB_SUCCESS';
export const CONFIRM_JOB_FAILED = 'CONFIRM_JOB_FAILED ';
export const CONFIRM_JOB_RESET = 'CONFIRM_JOB_RESET';

// Action Creators: Get All Jobs.
const getAllJobsStart = (payload) => ({ type: GET_ALL_JOBS_START, payload });
const getAllJobsSuccess = (payload) => ({ type: GET_ALL_JOBS_SUCCESS, payload });
const getAllJobsFailed = (payload) => ({ type: GET_ALL_JOBS_FAILED, payload });
const getAllJobsReset = () => ({ type: GET_ALL_JOBS_RESET });

// Action Creators: Get One Job.
const getOneJobStart = (payload) => ({ type: GET_ONE_JOB_START, payload });
const getOneJobSuccess = (payload) => ({ type: GET_ONE_JOB_SUCCESS, payload });
const getOneJobFailed = (payload) => ({ type: GET_ONE_JOB_FAILED, payload });
const getOneJobReset = () => ({ type: GET_ONE_JOB_RESET });

// Action Creators: Create Job.
const createJobStart = (payload) => ({ type: CREATE_JOB_START, payload });
const createJobSuccess = (payload) => ({ type: CREATE_JOB_SUCCESS, payload });
const createJobFailed = (payload) => ({ type: CREATE_JOB_FAILED, payload });
const createJobReset = () => ({ type: CREATE_JOB_RESET });

//Actions Creators: Update one Job.
const updateJobStart = (payload) => ({ type: UPDATE_JOB_START, payload });
const updateJobSuccess = (payload) => ({ type: UPDATE_JOB_SUCCESS, payload });
const updateJobFailed = (payload) => ({ type: UPDATE_JOB_FAILED, payload });
const updateJobReset = () => ({ type: UPDATE_JOB_RESET });

// Action Creators: Delete One Job.
const deleteJobStart = (payload) => ({ type: DELETE_JOB_START, payload });
const deleteJobSuccess = (payload) => ({ type: DELETE_JOB_SUCCESS, payload });
const deleteJobFailed = (payload) => ({ type: DELETE_JOB_FAILED, payload });
const deleteJobReset = () => ({ type: DELETE_JOB_RESET });

// Action Creators: Count Jobs.
const countJobsStart = (payload) => ({ type: COUNT_JOBS_START, payload });
const countJobsSuccess = (payload) => ({ type: COUNT_JOBS_SUCCESS, payload });
const countJobsFailed = (payload) => ({ type: COUNT_JOBS_FAILED, payload });
const countJobsReset = () => ({ type: COUNT_JOBS_RESET });

// Action Creators: Confirm Job.
const confirmJobStart = (payload) => ({ type: CONFIRM_JOB_START, payload });
const confirmJobSuccess = (payload) => ({ type: CONFIRM_JOB_SUCCESS, payload });
const confirmJobFailed = (payload) => ({ type: CONFIRM_JOB_FAILED, payload });
const confirmJobReset = () => ({ type: CONFIRM_JOB_RESET });

// Actions: Get All Jobs
export const getAllJobs = (payload, options = {}) => {
  const { page, limit, pagination, status, neighbourhood, expired } = payload;
  const {} = options;
  return async (dispatch) => {
    try {
      dispatch(getAllJobsStart({ loading: true, success: false, data: null, error: false, errorMessage: null }));

      const result = await ApiClient.get('/jobs', {
        params: { page, limit, pagination, status, neighbourhood, expired },
      });

      if (result.data?.success) {
        const { jobs } = result.data.data;
        dispatch(
          getAllJobsSuccess({
            loading: false,
            success: true,
            data: { jobs },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        const errorMessage = result.data?.error || 'Internal Server Error!';
        dispatch(
          getAllJobsFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: errorMessage,
          })
        );

        dispatch({
          type: '@snackbar/SNACKBAR_OPEN',
          open: true,
          message: errorMessage,
          variant: 'alert',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          alertSeverity: 'error',
          close: true,
        });
      }
    } catch (error) {
      const errorMessage =
        error.response && error.response.data && error.response.data.error
          ? error.response.data.error
          : 'Something went wrong!';

      dispatch(
        getAllJobsFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: errorMessage,
        })
      );

      dispatch({
        type: '@snackbar/SNACKBAR_OPEN',
        open: true,
        message: errorMessage,
        variant: 'alert',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        alertSeverity: 'error',
        close: true,
      });
    }
  };
};

// Actions: Clear All Jobs.
export const clearAllJobs = () => getAllJobsReset();

// Actions: Get One Job.
export const getOneJob = (payload, options = {}) => {
  const { jobId } = payload;
  const {} = options;
  return async (dispatch) => {
    try {
      dispatch(getOneJobStart({ loading: true, success: false, data: null, error: false, errorMessage: null }));

      const result = await ApiClient.get(`/jobs/${jobId}`);

      if (result.data?.success) {
        const { job } = result.data.data;

        dispatch(getOneJobSuccess({ loading: false, success: true, data: { job }, error: false, errorMessage: null }));
      } else {
        const errorMessage = result.data?.error || 'Internal Server Error!';
        dispatch(
          getOneJobFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: errorMessage,
          })
        );

        dispatch({
          type: '@snackbar/SNACKBAR_OPEN',
          open: true,
          message: errorMessage,
          variant: 'alert',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          alertSeverity: 'error',
          close: true,
        });
      }
    } catch (error) {
      const errorMessage =
        error.response && error.response.data && error.response.data.error
          ? error.response.data.error
          : 'Something went wrong!';

      dispatch(
        getOneJobFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: errorMessage,
        })
      );

      dispatch({
        type: '@snackbar/SNACKBAR_OPEN',
        open: true,
        message: errorMessage,
        variant: 'alert',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        alertSeverity: 'error',
        close: true,
      });
    }
  };
};

// Actions: Clear One Job.
export const clearOneJob = () => getOneJobReset();

// Actions: Create New Job.
export const createJob = (payload, options = {}) => {
  const {} = options;
  return async (dispatch) => {
    try {
      dispatchLoading(dispatch, createJobStart);
      const fields = ['title', 'description', 'neighbourhood', 'startDate', 'endDate', 'image', 'status'];
      const formData = new FormData();
      fields.forEach((field) => payload[field] && formData.append(field, payload[field]));

      const result = await ApiClient.post(`/jobs`, formData);
      if (result.data?.success) {
        const { job } = result.data.data;

        dispatchSuccess(dispatch, createJobSuccess, job);
        showSnackbar(dispatch, 'Job created successfully', 'success');
      } else {
        const errorMessage = result.data?.error || 'Something went wrong!';
        dispatchError(dispatch, createJobFailed, errorMessage);
        showSnackbar(dispatch, errorMessage, 'error');
      }
    } catch (error) {
      const errorMessage = getAxiosError(error);

      dispatchError(dispatch, createJobFailed, errorMessage);
      showSnackbar(dispatch, errorMessage, 'error');
    }
  };
};

// Actions: Clear Created Job.
export const clearCreatedJob = () => createJobReset();

//Actions: Update One Job
export const updateJob = (payload, options = {}) => {
  const { jobId } = payload;
  const {} = options;
  return async (dispatch) => {
    try {
      dispatchLoading(dispatch, updateJobStart);
      const fields = ['title', 'description', 'startDate', 'endDate', 'image'];
      const formData = new FormData();
      fields.forEach((field) => payload[field] && formData.append(field, payload[field]));

      const result = await ApiClient.put(`/jobs/${jobId}`, formData);
      if (result.data?.success) {
        const { job } = result.data.data;
        dispatchSuccess(dispatch, updateJobSuccess, job);
        showSnackbar(dispatch, 'Job updated successfully', 'success');
      } else {
        const errorMessage = result.data?.error || 'Something went wrong!';
        dispatchError(dispatch, updateJobFailed, errorMessage);
        showSnackbar(dispatch, errorMessage, 'error');
      }
    } catch (error) {
      const errorMessage = getAxiosError(error);

      dispatchError(dispatch, updateJobFailed, errorMessage);
      showSnackbar(dispatch, errorMessage, 'error');
    }
  };
};

// Actions: Clear Update One Job.
export const clearUpdateJob = () => updateJobReset();

// Actions: Delete One Job.
export const deleteOneJob = (payload, options = {}) => {
  const { jobId } = payload;
  const {} = options;
  return async (dispatch) => {
    try {
      dispatch(deleteJobStart({ loading: true, success: false, data: null, error: false, errorMessage: null }));

      const result = await ApiClient.delete(`/jobs/${jobId}`);
      if (result.data?.success) {
        const { job } = result.data.data;
        dispatch(deleteJobSuccess({ loading: false, success: true, data: { job }, error: false, errorMessage: null }));

        dispatch({
          type: '@snackbar/SNACKBAR_OPEN',
          open: true,
          message: 'Job deleted successfully',
          variant: 'alert',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          alertSeverity: 'success',
          close: true,
        });
      } else {
        const errorMessage = result.data?.error || 'Internal Server Error!';
        dispatch(
          deleteJobFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: errorMessage,
          })
        );

        dispatch({
          type: '@snackbar/SNACKBAR_OPEN',
          open: true,
          message: errorMessage,
          variant: 'alert',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          alertSeverity: 'error',
          close: true,
        });
      }
    } catch (error) {
      const errorMessage =
        error.response && error.response.data && error.response.data.error
          ? error.response.data.error
          : 'Something went wrong!';

      dispatch(
        deleteJobFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: errorMessage,
        })
      );

      dispatch({
        type: '@snackbar/SNACKBAR_OPEN',
        open: true,
        message: errorMessage,
        variant: 'alert',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        alertSeverity: 'error',
        close: true,
      });
    }
  };
};

// Actions: Clear Deleted Job.
export const clearDeletedJob = () => deleteJobReset();

// Actions: Count Jobs.
export const countJobs = (payload, options = {}) => {
  const { userId } = payload;

  const {} = options;
  return async (dispatch) => {
    try {
      dispatch(countJobsStart({ loading: true, success: false, data: null, error: false, errorMessage: null }));

      const result = await ApiClient.get(`/jobs/${userId}/count`);

      if (result.data?.success) {
        const job = result.data.data;
        dispatch(countJobsSuccess({ loading: false, success: true, data: { job }, error: false, errorMessage: null }));

        // dispatch({
        //   type: '@snackbar/SNACKBAR_OPEN',
        //   open: true,
        //   message: 'Events counted successfully',
        //   variant: 'alert',
        //   anchorOrigin: {
        //     vertical: 'top',
        //     horizontal: 'right',
        //   },
        //   alertSeverity: 'success',
        //   close: true,
        // });
      } else {
        const errorMessage = result.data?.error || 'Internal Server Error!';
        dispatch(
          countJobsFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: errorMessage,
          })
        );

        dispatch({
          type: '@snackbar/SNACKBAR_OPEN',
          open: true,
          message: errorMessage,
          variant: 'alert',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          alertSeverity: 'error',
          close: true,
        });
      }
    } catch (error) {
      const errorMessage =
        error.response && error.response.data && error.response.data.error
          ? error.response.data.error
          : 'Something went wrong!';

      dispatch(
        countJobsFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: errorMessage,
        })
      );

      dispatch({
        type: '@snackbar/SNACKBAR_OPEN',
        open: true,
        message: errorMessage,
        variant: 'alert',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        alertSeverity: 'error',
        close: true,
      });
    }
  };
};

// Actions: Clear Count Jobs.
export const clearCountJobs = () => countJobsReset();

// Actions: Confirm Job.
export const confirmJob = (payload, options = {}) => {
  const { jobId } = payload;

  const {} = options;
  return async (dispatch) => {
    try {
      dispatch(confirmJobStart({ loading: true, success: false, data: null, error: false, errorMessage: null }));
      const fields = ['title', 'description', 'startDate', 'endDate', 'neighbourhood', 'status'];
      const formData = new FormData();
      fields.forEach((field) => payload[field] && formData.append(field, payload[field]));
      const result = await ApiClient.put(`/jobs/${jobId}`, formData);

      if (result.data?.success) {
        const job = result.data.data;
        dispatch(confirmJobSuccess({ loading: false, success: true, data: { job }, error: false, errorMessage: null }));

        dispatch({
          type: '@snackbar/SNACKBAR_OPEN',
          open: true,
          message: 'Job approved successfully',
          variant: 'alert',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          alertSeverity: 'success',
          close: true,
        });
      } else {
        const errorMessage = result.data?.error || 'Internal Server Error!';
        dispatch(
          confirmJobFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: errorMessage,
          })
        );

        dispatch({
          type: '@snackbar/SNACKBAR_OPEN',
          open: true,
          message: errorMessage,
          variant: 'alert',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          alertSeverity: 'error',
          close: true,
        });
      }
    } catch (error) {
      const errorMessage =
        error.response && error.response.data && error.response.data.error
          ? error.response.data.error
          : 'Something went wrong!';

      dispatch(
        confirmJobFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: errorMessage,
        })
      );

      dispatch({
        type: '@snackbar/SNACKBAR_OPEN',
        open: true,
        message: errorMessage,
        variant: 'alert',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        alertSeverity: 'error',
        close: true,
      });
    }
  };
};

// Actions: Clear Confirm Job.
export const clearConfirmJob = () => confirmJobReset();
