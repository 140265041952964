import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// sample page routing
const Users = Loadable(lazy(() => import('views/DashBoard/Users/Users')));
const ViewUser = Loadable(lazy(() => import('views/DashBoard/Users/ViewUser')));
const Events = Loadable(lazy(() => import('views/DashBoard/Events/Events')));
const ViewEvent = Loadable(lazy(() => import('views/DashBoard/Events/ViewEvent')));
const Jobs = Loadable(lazy(() => import('views/DashBoard/Jobs/Jobs')));
const ViewMainJob = Loadable(lazy(() => import('views/DashBoard/Jobs/ViewMainJob')));
const ViewJobSuggestion = Loadable(lazy(() => import('views/DashBoard/Jobs/ViewJobSuggestion')));
const Complaints = Loadable(lazy(() => import('views/DashBoard/Complaints/Complaints')));
const ViewComplaint = Loadable(lazy(() => import('views/DashBoard/Complaints/ViewComplaint')));
const Neighbourhoods = Loadable(lazy(() => import('views/DashBoard/Neighbourhoods/Neighbourhoods')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: 'dashboard/neighbourhoods',
      element: <Neighbourhoods />,
    },
    {
      path: 'dashboard/users',
      element: <Users />,
    },
    {
      path: 'dashboard/users/viewuser/:id',
      element: <ViewUser />,
    },
    {
      path: 'dashboard/events',
      element: <Events />,
    },
    {
      path: 'dashboard/events/viewevent/:id',
      element: <ViewEvent />,
    },
    {
      path: 'dashboard/jobs',
      element: <Jobs />,
    },
    {
      path: 'dashboard/jobs/viewmainjob/:id',
      element: <ViewMainJob />,
    },
    {
      path: 'dashboard/jobs/viewjobsuggestion/:id',
      element: <ViewJobSuggestion />,
    },
    {
      path: 'dashboard/complaints',
      element: <Complaints />,
    },
    {
      path: 'dashboard/complaints/viewcomplaint/:id',
      element: <ViewComplaint />,
    },
  ],
};

export default MainRoutes;
