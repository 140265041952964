// Imports: third-party packages.
import jwtDecode from 'jwt-decode';

// Imports: local files.
import ApiClient from '../../services/ApiClient';

// Actions Types: Login User.
export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGIN_RESET = 'LOGIN_RESET';

// Actions Types: Logout User.
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILED = 'LOGOUT_FAILED';

// Actions Types: Save User.
export const SAVE_START = 'SAVE_START';
export const SAVE_SUCCESS = 'SAVE_SUCCESS';
export const SAVE_FAILED = 'SAVE_FAILED';
export const SAVE_RESET = 'SAVE_RESET';

// Action Types: Forgot Password.
export const FORGOT_START = 'FORGOT_START';
export const FORGOT_SUCCESS = 'FORGOT_SUCCESS';
export const FORGOT_FAILED = 'FORGOT_FAILED';
export const FORGOT_RESET = 'FORGOT_RESET';

// Action Types: Reset Password.
export const RESET_START = 'RESET_START';
export const RESET_SUCCESS = 'RESET_SUCCESS';
export const RESET_FAILED = 'RESET_FAILED';
export const RESET_RESET = 'RESET_RESET';

// Action Creators: Login User.
const loginStart = (payload) => ({ type: LOGIN_START, payload });
const loginSuccess = (payload) => ({ type: LOGIN_SUCCESS, payload });
const loginFailed = (payload) => ({ type: LOGIN_FAILED, payload });
const loginReset = () => ({ type: LOGIN_RESET });

// Action Creators: Logout User.
const logoutSuccess = () => ({ type: LOGOUT_SUCCESS });
const logoutFailed = () => ({ type: LOGOUT_FAILED });

// Action Creators: Save User.
const saveStart = (payload) => ({ type: SAVE_START, payload });
const saveSuccess = (payload) => ({ type: SAVE_SUCCESS, payload });
const saveFailed = (payload) => ({ type: SAVE_FAILED, payload });
const saveReset = (payload) => ({ type: SAVE_RESET, payload });

// Action Creators: Forgot Password.
const forgotStart = (payload) => ({ type: FORGOT_START, payload });
const forgotSuccess = (payload) => ({ type: FORGOT_SUCCESS, payload });
const forgotFailed = (payload) => ({ type: FORGOT_FAILED, payload });
const forgotReset = () => ({ type: FORGOT_RESET });

// Action Creators: Reset Password.
const resetStart = (payload) => ({ type: RESET_START, payload });
const resetSuccess = (payload) => ({ type: RESET_SUCCESS, payload });
const resetFailed = (payload) => ({ type: RESET_FAILED, payload });
const resetReset = () => ({ type: RESET_RESET });

// Actions: Login User.
export const login = (payload, options = {}) => {
  const { email, password } = payload;
  const {} = options;
  return async (dispatch) => {
    try {
      dispatch(loginStart({ loading: true, success: false, data: null, error: false, errorMessage: null }));
      dispatch(saveStart({ loading: true, success: false, data: null, error: false, errorMessage: null }));

      const result = await ApiClient.post('/auth/admin/login', { email, password });

      if (result.data?.success) {
        const { token } = result.data.data;
        const { id, email, role, iat, exp } = jwtDecode(token);

        const expAt = new Date(0);
        expAt.setUTCMilliseconds(exp * 1000);

        if (new Date(Date.now()) > new Date(expAt)) {
          localStorage.clear();
          dispatch(clearLogin());
          dispatch(clearSave());
          return window.location.replace('/login');
        }

        if (localStorage.getItem('lagjjaime-token')) localStorage.removeItem('lagjjaime-token');
        localStorage.setItem('lagjjaime-token', token);

        dispatch(loginSuccess({ loading: false, success: true, data: { token }, error: false, errorMessage: null }));
        dispatch(
          saveSuccess({
            loading: false,
            success: true,
            data: { id, email, role },
            error: false,
            errorMessage: null,
          })
        );

        return window.location.replace('/dashBoard/neighbourhoods/');
      } else {
        const errorMessage = result.data?.error || 'Something went wrong!';
        dispatch(
          loginFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: errorMessage,
          })
        );
        dispatch(
          saveFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: errorMessage,
          })
        );

        dispatch({
          type: '@snackbar/SNACKBAR_OPEN',
          open: true,
          message: errorMessage,
          variant: 'alert',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          alertSeverity: 'error',
          close: true,
        });
      }
    } catch (error) {
      const errorMessage =
        error.response && error.response.data && error.response.data.error
          ? error.response.data.error
          : 'Something went wrong!';

      dispatch(
        loginFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: errorMessage,
        })
      );
      dispatch(
        saveFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: errorMessage,
        })
      );
      dispatch({
        type: '@snackbar/SNACKBAR_OPEN',
        open: true,
        message: errorMessage,
        variant: 'alert',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        alertSeverity: 'error',
        close: true,
      });
    }
  };
};

// Actions: Clear Login.
export const clearLogin = () => loginReset();

// Actions: Logout User.
export const logout = (options = {}) => {
  return async (dispatch) => {
    try {
      // Clear local storage.
      localStorage.clear();

      // Empty redux state that relates to user.
      clearLogin();
      clearSave();
      clearReset();

      logoutSuccess();
      return window.location.replace('/login');
    } catch (error) {
      logoutFailed();

      const errorMessage = error.message || 'Failed to log out!';

      dispatch({
        type: '@snackbar/SNACKBAR_OPEN',
        open: true,
        message: errorMessage,
        variant: 'alert',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        alertSeverity: 'error',
        close: true,
      });
    }
  };
};

// Actions: Forgot Password.
export const forgotPassword = (payload, options = {}) => {
  const { email } = payload;
  const {} = options;
  return async (dispatch) => {
    try {
      dispatch(forgotStart({ loading: true, success: false, data: null, error: false, errorMessage: null }));

      const result = await ApiClient.post('/authentication/forgot', { email });

      if (result.data?.success) {
        const { sent } = result.data.data;
        dispatch(forgotSuccess({ loading: false, success: true, data: { sent }, error: false, errorMessage: null }));
      } else {
        dispatch(
          forgotFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: result.data?.error || 'Internal Server Error',
          })
        );
      }
    } catch (error) {
      const errorMessage =
        error.response && error.response.data && error.response.data.error
          ? error.response.data.error
          : 'Something went wrong!';
      dispatch(
        forgotFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: errorMessage,
        })
      );
    }
  };
};

// Actions: Clear Forgot.
export const clearForgot = () => forgotReset();

// Actions: Reset Password.
export const resetPassword = (payload, options = {}) => {
  const { resetToken, email, password, passwordConfirm } = payload;
  const {} = options;
  return async (dispatch) => {
    try {
      dispatch(resetStart({ loading: true, success: false, data: null, error: false, errorMessage: null }));
      dispatch(saveStart({ loading: true, success: false, data: null, error: false, errorMessage: null }));

      const result = await ApiClient.post(`/authentication/reset/${resetToken}`, {
        email,
        password,
        passwordConfirm,
      });

      if (result.data?.success) {
        const { token } = result.data.data;
        const { id, email, role, iat, exp } = jwtDecode(token);

        const expAt = new Date(0);
        expAt.setUTCMilliseconds(exp * 1000);

        if (new Date(Date.now()) > new Date(expAt)) {
          localStorage.clear();
          dispatch(clearLogin());
          dispatch(clearSave());
          return window.location.replace('/login');
        }

        if (localStorage.getItem('rea-token')) localStorage.removeItem('rea-token');
        localStorage.setItem('rea-token', token);

        dispatch(resetSuccess({ loading: false, success: true, data: { token }, error: false, errorMessage: null }));
        dispatch(
          saveSuccess({
            loading: false,
            success: true,
            data: { id, email, role },
            error: false,
            errorMessage: null,
          })
        );

        return window.location.replace('/users');
      } else {
        const errorMessage = result.data?.error || 'Something went wrong!';
        dispatch(
          resetFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: errorMessage,
          })
        );

        dispatch(
          saveFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: errorMessage,
          })
        );

        dispatch({
          type: '@snackbar/SNACKBAR_OPEN',
          open: true,
          message: errorMessage,
          variant: 'alert',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          alertSeverity: 'error',
          close: true,
        });
      }
    } catch (error) {
      const errorMessage =
        error.response && error.response.data && error.response.data.error
          ? error.response.data.error
          : 'Something went wrong!';

      dispatch(
        resetFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: errorMessage,
        })
      );

      dispatch(
        saveFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: errorMessage,
        })
      );

      dispatch({
        type: '@snackbar/SNACKBAR_OPEN',
        open: true,
        message: errorMessage,
        variant: 'alert',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        alertSeverity: 'error',
        close: true,
      });
    }
  };
};

// Actions: Clear Reset.
export const clearReset = () => resetReset();

// Actions: Clear Save.
export const clearSave = () => saveReset();
