// Imports: local files.
import { dispatchError, dispatchLoading, dispatchSuccess, getAxiosError, showSnackbar } from 'utils/redux';
import ApiClient from '../../services/ApiClient';

// Actions Types: Get All Events.
export const GET_ALL_EVENTS_START = 'GET_ALL_EVENTS_START';
export const GET_ALL_EVENTS_SUCCESS = 'GET_ALL_EVENTS_SUCCESS';
export const GET_ALL_EVENTS_FAILED = 'GET_ALL_EVENTS_FAILED';
export const GET_ALL_EVENTS_RESET = 'GET_ALL_EVENTS_RESET';

// Actions Types: Get One Event.
export const GET_ONE_EVENT_START = 'GET_ONE_EVENT_START';
export const GET_ONE_EVENT_SUCCESS = 'GET_ONE_EVENT_SUCCESS';
export const GET_ONE_EVENT_FAILED = 'GET_ONE_EVENT_FAILED';
export const GET_ONE_EVENT_RESET = 'GET_ONE_EVENT_RESET';

// Actions Types: Create Event.
export const CREATE_EVENT_START = 'CREATE_EVENT_START';
export const CREATE_EVENT_SUCCESS = 'CREATE_EVENT_SUCCESS';
export const CREATE_EVENT_FAILED = 'CREATE_EVENT_FAILED';
export const CREATE_EVENT_RESET = 'CREATE_EVENT_RESET';

//Actions Type:Update One Event
export const UPDATE_EVENT_START = 'UPDATE_EVENT_START';
export const UPDATE_EVENT_SUCCESS = 'UPDATE_EVENT_SUCCESS';
export const UPDATE_EVENT_FAILED = 'UPDATE_EVENT_FAILED';
export const UPDATE_EVENT_RESET = 'UPDATE_EVENT_RESET';

// Actions Types: Delete One Event.
export const DELETE_EVENT_START = 'DELETE_EVENT_START';
export const DELETE_EVENT_SUCCESS = 'DELETE_EVENT_SUCCESS';
export const DELETE_EVENT_FAILED = 'DELETE_EVENT_FAILED ';
export const DELETE_EVENT_RESET = 'DELETE_EVENT_RESET';

// Actions Types: Count Events.
export const COUNT_EVENTS_START = 'COUNT_EVENTS_START';
export const COUNT_EVENTS_SUCCESS = 'COUNT_EVENTS_SUCCESS';
export const COUNT_EVENTS_FAILED = 'COUNT_EVENTS_FAILED ';
export const COUNT_EVENTS_RESET = 'COUNT_EVENTS_RESET';

// Action Creators: Get All Events.
const getAllEventsStart = (payload) => ({ type: GET_ALL_EVENTS_START, payload });
const getAllEventsSuccess = (payload) => ({ type: GET_ALL_EVENTS_SUCCESS, payload });
const getAllEventsFailed = (payload) => ({ type: GET_ALL_EVENTS_FAILED, payload });
const getAllEventsReset = () => ({ type: GET_ALL_EVENTS_RESET });

// Action Creators: Get One Event.
const getOneEventStart = (payload) => ({ type: GET_ONE_EVENT_START, payload });
const getOneEventSuccess = (payload) => ({ type: GET_ONE_EVENT_SUCCESS, payload });
const getOneEventFailed = (payload) => ({ type: GET_ONE_EVENT_FAILED, payload });
const getOneEventReset = () => ({ type: GET_ONE_EVENT_RESET });

// Action Creators: Create Event.
const createEventStart = (payload) => ({ type: CREATE_EVENT_START, payload });
const createEventSuccess = (payload) => ({ type: CREATE_EVENT_SUCCESS, payload });
const createEventFailed = (payload) => ({ type: CREATE_EVENT_FAILED, payload });
const createEventReset = () => ({ type: CREATE_EVENT_RESET });

//Actions Creators: Update one Event.
const updateEventStart = (payload) => ({ type: UPDATE_EVENT_START, payload });
const updateEventSuccess = (payload) => ({ type: UPDATE_EVENT_SUCCESS, payload });
const updateEventFailed = (payload) => ({ type: UPDATE_EVENT_FAILED, payload });
const updateEventReset = () => ({ type: UPDATE_EVENT_RESET });

// Action Creators: Delete One Event.
const deleteEventStart = (payload) => ({ type: DELETE_EVENT_START, payload });
const deleteEventSuccess = (payload) => ({ type: DELETE_EVENT_SUCCESS, payload });
const deleteEventFailed = (payload) => ({ type: DELETE_EVENT_FAILED, payload });
const deleteEventReset = () => ({ type: DELETE_EVENT_RESET });

// Action Creators: Count Events.
const countEventsStart = (payload) => ({ type: COUNT_EVENTS_START, payload });
const countEventsSuccess = (payload) => ({ type: COUNT_EVENTS_SUCCESS, payload });
const countEventsFailed = (payload) => ({ type: COUNT_EVENTS_FAILED, payload });
const countEventsReset = () => ({ type: COUNT_EVENTS_RESET });

// Actions: Get All Events
export const getAllEvents = (payload, options = {}) => {
  const { page, limit, pagination, expired } = payload;
  const {} = options;
  return async (dispatch) => {
    try {
      dispatch(getAllEventsStart({ loading: true, success: false, data: null, error: false, errorMessage: null }));

      const result = await ApiClient.get('/events', { params: { page, limit, pagination, expired } });

      if (result.data?.success) {
        const { events } = result.data.data;
        dispatch(
          getAllEventsSuccess({
            loading: false,
            success: true,
            data: { events },
            error: false,
            errorMessage: null,
          })
        );
      } else {
        const errorMessage = result.data?.error || 'Internal Server Error!';
        dispatch(
          getAllEventsFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: errorMessage,
          })
        );

        dispatch({
          type: '@snackbar/SNACKBAR_OPEN',
          open: true,
          message: errorMessage,
          variant: 'alert',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          alertSeverity: 'error',
          close: true,
        });
      }
    } catch (error) {
      const errorMessage =
        error.response && error.response.data && error.response.data.error
          ? error.response.data.error
          : 'Something went wrong!';

      dispatch(
        getAllEventsFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: errorMessage,
        })
      );

      dispatch({
        type: '@snackbar/SNACKBAR_OPEN',
        open: true,
        message: errorMessage,
        variant: 'alert',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        alertSeverity: 'error',
        close: true,
      });
    }
  };
};

// Actions: Clear All Events.
export const clearAllEvents = () => getAllEventsReset();

// Actions: Get One Event.
export const getOneEvent = (payload, options = {}) => {
  const { eventId } = payload;
  const {} = options;
  return async (dispatch) => {
    try {
      dispatch(getOneEventStart({ loading: true, success: false, data: null, error: false, errorMessage: null }));

      const result = await ApiClient.get(`/events/${eventId}`);
      if (result.data?.success) {
        const { event } = result.data.data;
        dispatch(
          getOneEventSuccess({ loading: false, success: true, data: { event }, error: false, errorMessage: null })
        );
      } else {
        const errorMessage = result.data?.error || 'Internal Server Error!';
        dispatch(
          getOneEventFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: errorMessage,
          })
        );

        dispatch({
          type: '@snackbar/SNACKBAR_OPEN',
          open: true,
          message: errorMessage,
          variant: 'alert',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          alertSeverity: 'error',
          close: true,
        });
      }
    } catch (error) {
      const errorMessage =
        error.response && error.response.data && error.response.data.error
          ? error.response.data.error
          : 'Something went wrong!';

      dispatch(
        getOneEventFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: errorMessage,
        })
      );

      dispatch({
        type: '@snackbar/SNACKBAR_OPEN',
        open: true,
        message: errorMessage,
        variant: 'alert',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        alertSeverity: 'error',
        close: true,
      });
    }
  };
};

// Actions: Clear One Event.
export const clearOneEvent = () => getOneEventReset();

// Actions: Create New Event.
export const createEvent = (payload, options = {}) => {
  const {} = options;
  return async (dispatch) => {
    try {
      dispatchLoading(dispatch, createEventStart);
      const fields = ['title', 'description', 'startDate', 'endDate', 'image'];
      const formData = new FormData();
      fields.forEach((field) => payload[field] && formData.append(field, payload[field]));

      const result = await ApiClient.post(`/events`, formData);
      if (result.data?.success) {
        const { event } = result.data.data;

        dispatchSuccess(dispatch, createEventSuccess, event);
        showSnackbar(dispatch, 'Event created successfully', 'success');
      } else {
        const errorMessage = result.data?.error || 'Something went wrong!';
        dispatchError(dispatch, createEventFailed, errorMessage);
        showSnackbar(dispatch, errorMessage, 'error');
      }
    } catch (error) {
      const errorMessage = getAxiosError(error);

      dispatchError(dispatch, createEventFailed, errorMessage);
      showSnackbar(dispatch, errorMessage, 'error');
    }
  };
};

// Actions: Clear Created Event.
export const clearCreatedEvent = () => createEventReset();

//Actions: Update One Event
export const updateEvent = (payload, options = {}) => {
  const { eventId } = payload;
  const {} = options;
  return async (dispatch) => {
    try {
      dispatchLoading(dispatch, updateEventStart);
      const fields = ['title', 'description', 'startDate', 'endDate', 'image'];
      const formData = new FormData();
      fields.forEach((field) => payload[field] && formData.append(field, payload[field]));
      const result = await ApiClient.put(`/events/${eventId}`, formData);
      if (result.data?.success) {
        const { event } = result.data.data;
        dispatchSuccess(dispatch, updateEventSuccess, event);
        showSnackbar(dispatch, 'Event updated successfully', 'success');
      } else {
        const errorMessage = result.data?.error || 'Something went wrong!';
        dispatchError(dispatch, updateEventFailed, errorMessage);
        showSnackbar(dispatch, errorMessage, 'error');
      }
    } catch (error) {
      const errorMessage = getAxiosError(error);

      dispatchError(dispatch, updateEventFailed, errorMessage);
      showSnackbar(dispatch, errorMessage, 'error');
    }
  };
};

// Actions: Clear Update One Event.
export const clearUpdateEvent = () => updateEventReset();

// Actions: Delete One Event.
export const deleteOneEvent = (payload, options = {}) => {
  const { eventId } = payload;
  const {} = options;
  return async (dispatch) => {
    try {
      dispatch(deleteEventStart({ loading: true, success: false, data: null, error: false, errorMessage: null }));

      const result = await ApiClient.delete(`/events/${eventId}`);
      if (result.data?.success) {
        const { event } = result.data.data;
        dispatch(
          deleteEventSuccess({ loading: false, success: true, data: { event }, error: false, errorMessage: null })
        );

        dispatch({
          type: '@snackbar/SNACKBAR_OPEN',
          open: true,
          message: 'Event deleted successfully',
          variant: 'alert',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          alertSeverity: 'success',
          close: true,
        });
      } else {
        const errorMessage = result.data?.error || 'Internal Server Error!';
        dispatch(
          deleteEventFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: errorMessage,
          })
        );

        dispatch({
          type: '@snackbar/SNACKBAR_OPEN',
          open: true,
          message: errorMessage,
          variant: 'alert',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          alertSeverity: 'error',
          close: true,
        });
      }
    } catch (error) {
      const errorMessage =
        error.response && error.response.data && error.response.data.error
          ? error.response.data.error
          : 'Something went wrong!';

      dispatch(
        deleteEventFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: errorMessage,
        })
      );

      dispatch({
        type: '@snackbar/SNACKBAR_OPEN',
        open: true,
        message: errorMessage,
        variant: 'alert',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        alertSeverity: 'error',
        close: true,
      });
    }
  };
};

// Actions: Clear Deleted Event.
export const clearDeletedEvent = () => deleteEventReset();

// Actions: Count Events.
export const countEvents = (payload, options = {}) => {
  const { userId } = payload;

  const {} = options;
  return async (dispatch) => {
    try {
      dispatch(countEventsStart({ loading: true, success: false, data: null, error: false, errorMessage: null }));

      const result = await ApiClient.get(`/events/${userId}/count`);

      if (result.data?.success) {
        const event = result.data.data;
        dispatch(
          countEventsSuccess({ loading: false, success: true, data: { event }, error: false, errorMessage: null })
        );

        // dispatch({
        //   type: '@snackbar/SNACKBAR_OPEN',
        //   open: true,
        //   message: 'Events counted successfully',
        //   variant: 'alert',
        //   anchorOrigin: {
        //     vertical: 'top',
        //     horizontal: 'right',
        //   },
        //   alertSeverity: 'success',
        //   close: true,
        // });
      } else {
        const errorMessage = result.data?.error || 'Internal Server Error!';
        dispatch(
          countEventsFailed({
            loading: false,
            success: false,
            data: null,
            error: true,
            errorMessage: errorMessage,
          })
        );

        dispatch({
          type: '@snackbar/SNACKBAR_OPEN',
          open: true,
          message: errorMessage,
          variant: 'alert',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          alertSeverity: 'error',
          close: true,
        });
      }
    } catch (error) {
      const errorMessage =
        error.response && error.response.data && error.response.data.error
          ? error.response.data.error
          : 'Something went wrong!';

      dispatch(
        countEventsFailed({
          loading: false,
          success: false,
          data: null,
          error: true,
          errorMessage: errorMessage,
        })
      );

      dispatch({
        type: '@snackbar/SNACKBAR_OPEN',
        open: true,
        message: errorMessage,
        variant: 'alert',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        alertSeverity: 'error',
        close: true,
      });
    }
  };
};

// Actions: Clear Count Events.
export const clearCountEvents = () => countEventsReset();
